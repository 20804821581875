import { computed, watch, toValue } from 'vue';
import type { ComputedRef, MaybeRefOrGetter } from 'vue';
import { toString, uniq } from 'lodash-es';
import { getValueByKey, areValuesEqual } from '../../utils';
import type { Path } from '../../shared/types';

interface Group {
  key: string;
  title?: string | null;
}

type UseOptionsListOptions<O = unknown, V = unknown> = {
  options: MaybeRefOrGetter<O[]>;
  groups?: MaybeRefOrGetter<Group[]>;
  optionDisabled?: Path<O> | ((option: O) => boolean);
  optionLabel?: Path<O> | ((option: O) => string);
  optionValue?: Path<O> | ((option: O) => V);
  optionGroup?: Path<O> | ((option: O) => string);
  trackValueBy?: (V extends unknown ? string : Path<V>) | ((value: V) => unknown);
  optionsLimit?: number;
  search?: MaybeRefOrGetter<string>;
  selectionMode?: 'single' | 'multiple';
  selected?: MaybeRefOrGetter<V | null | V[]>;
};

export interface OptionsListItem<O = unknown, V = unknown> {
  label: string;
  disabled: boolean;
  value: V;
  selected: boolean;
  group: string | null;
  origin: O | null;
}

export interface OptionsListGroup<O = unknown, V = unknown> {
  key: string;
  title: string | null;
  options: OptionsListItem<O, V>[];
}
interface UseOptionsListReturn<O = unknown, V = unknown> {
  optionsList: ComputedRef<OptionsListItem<O, V>[]>;
  filteredOptionsList: ComputedRef<OptionsListItem<O, V>[]>;
  optionsGroups: ComputedRef<OptionsListGroup<O, V>[]>;
  optionsGroupsWithEmpty: ComputedRef<OptionsListGroup<O, V>[]>;
  selectedOptions: ComputedRef<OptionsListItem<O, V>[]>;
  selectedOption: ComputedRef<OptionsListItem<O, V> | null>;
  allOptionsSelected: ComputedRef<boolean>;
}

export function useOptionsList<O = unknown, V = unknown>(
  options: UseOptionsListOptions<O, V>,
): UseOptionsListReturn<O, V> {
  function normalizeForTextSearch(value: unknown): string {
    return toString(value).toLowerCase().trim();
  }

  function getOptionLabel(option: O): string {
    const { optionLabel } = options;

    return toString(optionLabel ? getValueByKey(option, optionLabel) : option);
  }

  function getOptionValue(option: O): V {
    const { optionValue } = options;

    return (optionValue ? getValueByKey(option, optionValue) : option) as V;
  }

  function getOptionGroup(option: O): string | null {
    const { optionGroup } = options;

    return optionGroup ? (getValueByKey(option, optionGroup) ?? null) : null;
  }

  function isOptionDisabled(option: O): boolean {
    const { optionDisabled } = options;

    return !!(optionDisabled ? getValueByKey(option, optionDisabled) : false);
  }

  function isOptionSelected(option: O): boolean {
    const value = getOptionValue(option);
    const { trackValueBy, selectionMode = 'single' } = options;
    const selected = toValue(options.selected);

    if (selectionMode === 'single') {
      return areValuesEqual<V>(value, selected as V, trackValueBy);
    } else if (selectionMode === 'multiple' && Array.isArray(selected)) {
      return selected.some((item) => areValuesEqual<V>(item, value, trackValueBy));
    }

    return false;
  }

  const optionsList = computed<OptionsListItem<O, V>[]>(() => {
    const items = toValue(options.options);

    return items.map<OptionsListItem<O, V>>((item) => {
      return {
        label: getOptionLabel(item),
        disabled: isOptionDisabled(item),
        value: getOptionValue(item),
        selected: isOptionSelected(item),
        group: getOptionGroup(item),
        origin: item,
      };
    });
  });

  const filteredOptionsList = computed<OptionsListItem<O, V>[]>(() => {
    const search = toValue(options.search);

    let result = optionsList.value;

    if (search) {
      const searchQuery = normalizeForTextSearch(search);

      result = result.filter(({ label }) => normalizeForTextSearch(label).includes(searchQuery));
    }

    return result.slice(0, options.optionsLimit ?? 300);
  });

  const autoGeneratedGroups = computed(() =>
    uniq(optionsList.value.map(({ group }) => group)).filter((item): item is string => !!item),
  );

  const optionsGroupsWithEmpty = computed(() => {
    const groups = toValue(options.groups);

    if (Array.isArray(groups)) {
      return groups.map(({ key, title }) => ({
        key,
        title: title ?? null,
        options: filteredOptionsList.value.filter((item) => item.group === key),
      }));
    }

    return autoGeneratedGroups.value.map((group) => ({
      key: group,
      title: group,
      options: filteredOptionsList.value.filter((item) => item.group === group),
    }));
  });

  const optionsGroups = computed(() =>
    optionsGroupsWithEmpty.value.filter((group) => group.options.length > 0),
  );

  let cachedSelectedOptions: OptionsListItem<O, V>[] = [];

  const selectedOptions = computed<OptionsListItem<O, V>[]>(() => {
    const { trackValueBy } = options;
    const selected = toValue(options.selected);

    if (selected === null || typeof selected === 'undefined') {
      return [];
    }

    return (Array.isArray(selected) ? selected : [selected]).map((value) => {
      // Select whole option. In this case we don't need to search selected option.
      if (!options.optionValue) {
        const valueAsOption = value as unknown as O;

        return {
          label: getOptionLabel(valueAsOption),
          value,
          selected: true,
          disabled: isOptionDisabled(valueAsOption),
          group: getOptionGroup(valueAsOption),
          origin: valueAsOption,
        };
      }

      // Select property of option (or result of function called with option as an argument).
      // Need to find option with the value equals to the selected value in the options list.
      // Maybe it exists in options list, maybe it was cached to not lose when options changes if external search is used.
      const selectedOption = [...optionsList.value, ...cachedSelectedOptions].find((item) => {
        return areValuesEqual<V>(value, item.value, trackValueBy);
      });

      if (selectedOption) {
        return {
          ...selectedOption,
          selected: true,
        };
      }

      // If selected option was not found it's not possible to construct fully correct option object.
      // Return at least something
      return {
        label: toString(value),
        disabled: false,
        value,
        selected: true,
        group: null,
        origin: null,
      };
    });
  });

  const allOptionsSelected = computed(() => {
    const { selectionMode = 'single' } = options;

    if (selectionMode === 'single') {
      return false;
    }

    return !optionsList.value.some(({ selected }) => !selected);
  });

  watch(
    selectedOptions,
    (value) => {
      cachedSelectedOptions = value;
    },
    { deep: true },
  );

  const selectedOption = computed(() => selectedOptions.value[0] ?? null);

  return {
    optionsList,
    filteredOptionsList,
    optionsGroups,
    optionsGroupsWithEmpty,
    selectedOptions,
    selectedOption,
    allOptionsSelected,
  };
}
