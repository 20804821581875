<script lang="ts" setup generic="T">
import { provide, inject, computed, toValue } from 'vue';
import { AriaRole } from '../../shared/types';
import { ACTION_LIST_CONTEXT } from './action-list-context';

interface Props {
  listRole?: AriaRole;
  itemRole?: AriaRole;
  itemSelectionAttribute?: 'aria-selected' | 'aria-checked';
  selectionMode?: 'single' | 'multiple';
  compact?: boolean;
  withDividers?: boolean;
}

const {
  listRole,
  itemRole,
  itemSelectionAttribute,
  selectionMode,
  compact = false,
  withDividers = false,
} = defineProps<Props>();

const context = inject(ACTION_LIST_CONTEXT, {});

const role = computed(() => listRole ?? toValue(context.listRole));

const attributes = computed(() => {
  return {
    'aria-multiselectable':
      role.value === 'listbox' && selectionMode === 'multiple' ? true : undefined,
  };
});

provide(ACTION_LIST_CONTEXT, {
  listRole: role,
  itemRole: computed(() => itemRole ?? toValue(context.itemRole)),
  itemSelectionAttribute: computed(
    () => itemSelectionAttribute ?? toValue(context.itemSelectionAttribute),
  ),
  selectionMode: computed(() => selectionMode ?? toValue(context.selectionMode)),
  compact: computed(() => compact ?? toValue(context.compact)),
  withDividers: computed(() => withDividers ?? toValue(context.withDividers)),
  onAfterSelect: context.onAfterSelect,
});
</script>

<template>
  <ul v-bind="attributes" :role="role" :class="$style.root">
    <slot />
  </ul>
</template>

<style lang="scss" module>
@use '../../styles/typography';

.root {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  margin: 0;
  list-style: none;
  font-family: typography.$font-family-primary;
}
</style>
