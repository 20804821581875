<script lang="ts" setup generic="T">
import { provide, computed } from 'vue';
import { ACTION_LIST_GROUP_CONTEXT } from './action-list-group-context';

interface Props {
  title?: string | null;
}

const { title } = defineProps<Props>();

provide(ACTION_LIST_GROUP_CONTEXT, {
  title: computed(() => title),
});
</script>

<template>
  <slot />
</template>
