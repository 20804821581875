<script lang="ts" setup generic="O = any, V = any">
import {
  ref,
  computed,
  watchEffect,
  nextTick,
  shallowRef,
  useId,
  watch,
  useTemplateRef,
  type VNode,
  type ComponentPublicInstance,
} from 'vue';
import { unrefElement, useFocus } from '@vueuse/core';
import { IconChevronDown } from '@tabler/icons-vue';
import { size as sizeMiddleware, type Middleware } from '@floating-ui/vue';
import { useFocusZone, useOptionsList, type OptionsListItem } from '../../composables';
import { Keys } from '../../shared/enums';
import type { SizeS, SizeM, SizeL, OptionsListProps } from '../../shared/types';
import { ObActionListContextProvider, ObActionList, ObActionListItem } from '../action-list';
import { ObScrollableContainer } from '../scrollable-container';
import { ObPrimitiveInput } from '../primitive-input';
import { ObSpinner } from '../spinner';
import { ObHostedDropdown } from '../hosted-dropdown';

type Props = OptionsListProps<O, V> & {
  id?: string;
  invalid?: boolean;
  disabled?: boolean;
  modelValue?: V | null;
  placeholder?: string;
  size?: SizeS | SizeM | SizeL;
  externalSearch?: boolean;
  optionsLoading?: boolean;
};

defineOptions({
  inheritAttrs: false,
});

const {
  id,
  disabled = false,
  invalid = false,
  size = 'm',
  options = [],
  optionDisabled,
  optionLabel,
  optionValue,
  trackValueBy,
  optionsLoading = false,
  externalSearch = false,
} = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: V | null];
  search: [search: string];
}>();

defineSlots<{
  default?: (props: {
    optionsList: OptionsListItem<O, V>[];
    selectOption: (option: OptionsListItem<O, V>) => void;
    selectedOption: OptionsListItem<O, V> | null;
  }) => VNode;
  noOptions?: (props: { search: string }) => VNode;
}>();

const hostRef = shallowRef<HTMLElement | null>();
const inputRef = shallowRef<HTMLElement | null>();
const containerRef = useTemplateRef<InstanceType<typeof ObHostedDropdown>>('container');
const containerElement = computed(() => containerRef.value?.$el as HTMLDivElement); // TODO: why defineExpose ignores real $el type?

const open = ref(false);

function floatingMiddleware(middleware: Middleware[]) {
  return [
    sizeMiddleware({
      apply({ elements }) {
        const { width } = elements.reference.getBoundingClientRect();
        Object.assign(elements.floating.style, {
          width: `${width}px`,
        });
      },
    }),
    ...middleware,
  ];
}

const listId = useId();
const { focused } = useFocus(inputRef);

let activeDescendant: HTMLElement | undefined;

const { focusFirst, focusLast, focus } = useFocusZone({
  container: containerElement,
  activeDescendantControl: inputRef,
  onActiveDescendantChanged: (current, previous, directlyActivated) => {
    activeDescendant = current;

    if (directlyActivated) {
      activeDescendant?.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  },
});

const modelValue = defineModel<V | null>({ default: null });

const inputValue = ref('');
const search = ref('');

watch(search, (value) => {
  emit('search', value);
});

const { filteredOptionsList: optionsList, selectedOption } = useOptionsList<O, V>({
  options: computed(() => options), // reactivity
  optionDisabled,
  optionLabel,
  optionValue,
  trackValueBy,
  selectionMode: 'single',
  selected: modelValue,
  search: computed(() => {
    if (externalSearch) {
      return '';
    }

    return search.value;
  }),
});

watchEffect(() => {
  if (selectedOption.value) {
    search.value = '';
    inputValue.value = selectedOption.value.label;
  }
});

function selectOption(option: OptionsListItem<O, V>) {
  modelValue.value = option.value;
}

function onItemSelect() {
  open.value = false;
  search.value = '';
}

function resetInputValue() {
  inputValue.value = selectedOption.value ? selectedOption.value.label : '';
}

function focusInput() {
  inputRef.value?.focus({ preventScroll: true });
}

function onClick(event: MouseEvent) {
  if (disabled) {
    return;
  }

  if (event.target === unrefElement(inputRef)) {
    open.value = true;
  } else {
    event.preventDefault();
    open.value = !open.value;
    focusInput();
  }

  if (open.value) {
    nextTick(() => {
      const selected = containerElement.value?.querySelector<HTMLElement>('[aria-selected="true"]');
      if (selected) {
        focus(selected);
      }
    });
  }
}

const EDITING_KEYS: string[] = [Keys.Space, Keys.Backspace, Keys.Delete];

function onInputKeydown(event: KeyboardEvent) {
  if (event.key === Keys.Enter) {
    if (activeDescendant) {
      event.preventDefault();
      event.stopImmediatePropagation();

      // Forward Enter key press to active descendant so that item gets activated
      const activeDescendantEvent = new KeyboardEvent(event.type, event);
      activeDescendant.dispatchEvent(activeDescendantEvent);
    }
    return;
  }

  if (event.key === Keys.Escape) {
    event.preventDefault();
    event.stopPropagation();
    open.value = false;
    search.value = '';
    resetInputValue();
    return;
  }

  if (open.value) {
    return;
  }

  if (event.key === Keys.ArrowDown || event.key === Keys.ArrowUp) {
    event.preventDefault();
    event.stopPropagation();
    open.value = true;
    nextTick(() => {
      if (event.key === Keys.ArrowDown) {
        focusFirst(true);
        return;
      }

      focusLast(true);
    });

    return;
  }

  if (event.key.length === 1 || EDITING_KEYS.includes(event.key)) {
    open.value = true;
  }
}

function onBlur(event: FocusEvent) {
  const relatedTarget = event.relatedTarget as HTMLElement;

  const hostElement = unrefElement(hostRef);

  if (hostElement === relatedTarget || hostElement?.contains(relatedTarget)) {
    return;
  }

  if (containerElement.value === relatedTarget || containerElement.value?.contains(relatedTarget)) {
    return;
  }

  open.value = false;
  search.value = '';
  resetInputValue();
}

function onInput(event: Event) {
  const { value } = event.target as HTMLInputElement;
  search.value = value;
  inputValue.value = value;

  if (!value) {
    modelValue.value = null;
  }

  nextTick(() => {
    focusFirst();
  });
}
</script>

<template>
  <ObHostedDropdown
    ref="container"
    :active="open"
    tabindex="-1"
    role="dialog"
    :floating-middleware="floatingMiddleware"
    @click-outside="open = false"
  >
    <template #host="{ hostProps }">
      <ObPrimitiveInput
        v-bind="hostProps"
        :ref="
          (el: Element | ComponentPublicInstance | null) => {
            hostProps.ref(el);
            hostRef = unrefElement(el as any); // TODO: how not to cast no any? Element vs HTMLElement
          }
        "
        :size
        :invalid
        :disabled
        :focused
        tabindex="-1"
        @focus="focusInput()"
        @click="onClick"
        @focusout="onBlur"
      >
        <input
          :id
          ref="inputRef"
          v-model="inputValue"
          type="text"
          :placeholder="placeholder"
          :disabled
          aria-autocomplete="list"
          :aria-controls="listId"
          :aria-expanded="open"
          aria-haspopup="listbox"
          :aria-owns="listId"
          autocomplete="off"
          role="combobox"
          @keydown="onInputKeydown"
          @input="onInput"
        />
        <template #icon>
          <span :class="[$style.arrow, { [$style.arrowRotated]: open }]">
            <IconChevronDown />
          </span>
        </template>
      </ObPrimitiveInput>
    </template>
    <ObScrollableContainer light>
      <div v-if="optionsLoading" :class="$style.noData">
        <ObSpinner size="48px" />
        Loading
      </div>
      <div v-else-if="!optionsList.length" :class="$style.noData">
        <slot name="noOptions" v-bind="{ search }">
          {{ search ? `No items found for '${search}'` : 'No items found' }}
        </slot>
      </div>
      <ObActionListContextProvider
        v-else
        list-role="listbox"
        item-role="option"
        :on-after-select="onItemSelect"
      >
        <ObActionList :id="listId" selection-mode="single" compact>
          <slot
            v-bind="{
              optionsList,
              selectedOption,
              selectOption,
            }"
          >
            <ObActionListItem
              v-for="option in optionsList"
              :key="option.label"
              :selected="option.selected"
              :disabled="option.disabled"
              @select="selectOption(option)"
            >
              {{ option.label }}
            </ObActionListItem>
          </slot>
        </ObActionList>
      </ObActionListContextProvider>
    </ObScrollableContainer>
  </ObHostedDropdown>
</template>

<style lang="scss" module>
@use '../../styles/colors';

.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  gap: 4px;
  padding: 48px 8px;
}

.arrow {
  color: colors.$surface-40;
  display: flex;
  font-size: 24px;
  width: 1em;
  height: 1em;
  margin-left: 12px;
  transition: transform 0.2s ease-in-out;
}

.arrowRotated {
  transform: rotate(180deg);
}
</style>
