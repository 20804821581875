<script lang="ts" setup>
import { shallowRef } from 'vue';
import { useFocusZone } from '../../composables';
import { injectStrict } from '../../utils';
import { OB_TABS_CONTEXT } from './shared';

const rootRef = shallowRef<HTMLDivElement | null>(null);

useFocusZone({
  container: rootRef,
  focusOutBehavior: 'wrap',
  bindKeys: ['ArrowLeft', 'ArrowRight'],
});

const { variant } = injectStrict(
  OB_TABS_CONTEXT,
  undefined,
  '<ObTabsList /> must be a child of <ObTabs /> component.',
);
</script>

<template>
  <div
    ref="rootRef"
    :class="variant === 'secondary' ? $style.secondary : $style.primary"
    role="tablist"
    aria-orientation="horizontal"
  >
    <slot />
  </div>
</template>

<style lang="scss" module>
@use '../../styles/shared';

.primary {
  @include shared.scrollbar();
  overflow-y: auto;
  display: flex;
  gap: 24px;
}

.secondary {
  display: inline-flex;
  vertical-align: top;
}
</style>
