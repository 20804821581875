<script lang="ts" setup>
import { ref, watchEffect, type TransitionProps, type TeleportProps } from 'vue';

type Props = {
  active: boolean;
} & Omit<TeleportProps, 'to'> &
  TransitionProps;

defineOptions({
  inheritAttrs: false,
});

const {
  active = false,
  disabled,
  defer,
  appear = true,
  css = true,
  mode = 'in-out',
  onAfterLeave,
  ...transitionProps
} = defineProps<Props>();

const portalActive = ref(false);

watchEffect(() => {
  if (active) {
    portalActive.value = true;
  }
});

function afterLeave(el: Element) {
  if (onAfterLeave) {
    const hooks = Array.isArray(onAfterLeave) ? onAfterLeave : [onAfterLeave];
    hooks.forEach((hook) => {
      if (typeof hook === 'function') {
        hook(el);
      }
    });
  }

  if (!active) {
    portalActive.value = false;
  }
}
</script>

<template>
  <Teleport v-if="portalActive" to="body" :disabled :defer>
    <Transition v-bind="{ appear, mode, css, ...transitionProps }" @after-leave="afterLeave">
      <slot />
    </Transition>
  </Teleport>
</template>
