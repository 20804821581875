<script lang="ts" setup>
import { inject, toValue, computed, type VNode } from 'vue';
import { AriaRole } from '../../shared/types';
import { ACTION_LIST_CONTEXT } from './action-list-context';
import { ACTION_LIST_GROUP_CONTEXT } from './action-list-group-context';

type Props = {
  role?: AriaRole;
};

const { role } = defineProps<Props>();

defineSlots<{
  default?: () => VNode;
}>();

const context = inject(ACTION_LIST_CONTEXT, {});
const groupContext = inject(ACTION_LIST_GROUP_CONTEXT, {});

const listRole = computed(() => toValue(context.listRole));
const ariaLabel = computed(() => toValue(groupContext.title));
</script>

<template>
  <ul
    :aria-label="ariaLabel ?? undefined"
    :role="role || (listRole ? 'group' : undefined)"
    :class="$style.root"
  >
    <slot />
  </ul>
</template>

<style lang="scss" module>
.root {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>
