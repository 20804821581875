<script lang="ts" setup>
import { ref, computed, watchEffect } from 'vue';
import { colord } from 'colord';
import { IconUser } from '@tabler/icons-vue';
import type { SizeS, SizeM, SizeL } from '../../shared/types';
import { stringToColor } from '../../utils';

interface Props {
  color?: string;
  name?: string;
  size?: SizeS | SizeM | SizeL;
  source?: string;
  textColor?: string;
}

const { color, name, size = 'm', source, textColor } = defineProps<Props>();

const imageLoaded = ref(false);

watchEffect(() => {
  if (!source) {
    imageLoaded.value = false;
    return;
  }

  const image = new window.Image();

  image.onload = () => {
    imageLoaded.value = true;
  };

  image.onerror = () => {
    imageLoaded.value = false;
  };

  image.src = source;
});

const initials = computed(() => {
  if (!name) {
    return '';
  }

  const [firstName, lastName] = name.split(' ');

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  } else {
    return firstName.charAt(0);
  }
});

const style = computed(() => {
  if (!name) {
    return {};
  }

  const backgroundColor = color || (name ? stringToColor(name) : undefined);

  return {
    color:
      textColor ||
      (backgroundColor
        ? colord(backgroundColor).brightness() <= 0.4
          ? '#fff'
          : '#000'
        : undefined),
    backgroundColor,
  };
});

const altText = computed(() => `${name} user avatar image`);
</script>

<template>
  <div
    :class="[
      $style.root,
      {
        [$style.sizeS]: size === 's',
        [$style.sizeM]: size === 'm',
        [$style.sizeL]: size === 'l',
      },
    ]"
    :style="style"
  >
    <div v-if="imageLoaded" :class="$style.image">
      <img :src="source" :alt="altText" />
    </div>
    <div v-else-if="name">{{ initials }}</div>
    <div v-else :class="$style.icon">
      <IconUser role="img" :aria-label="altText" />
    </div>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/typography';
@use '../../styles/colors';

.root {
  display: inline-flex;
  vertical-align: middle;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: colors.$surface-16;
  color: colors.$primary-80;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 1.25em;
  font-weight: 600;
}

.image {
  display: flex;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.icon {
  display: flex;
  font-size: 24px;
}

.sizeS {
  width: 32px;
  height: 32px;
  font-size: 12px;
}

.sizeS .icon {
  font-size: 16px;
}

.sizeM {
  width: 48px;
  height: 48px;
  font-size: 16px;
}

.sizeM .icon {
  font-size: 24px;
}

.sizeL {
  width: 96px;
  height: 96px;
  font-size: 24px;
}

.sizeL .icon {
  font-size: 48px;
}
</style>
