<script lang="ts" setup>
import { hasSlotContent } from '../../utils';
import type { SizeS, SizeM, SizeL } from '../../shared/types';

interface Props {
  disabled?: boolean;
  focused?: boolean;
  invalid?: boolean;
  size?: SizeS | SizeM | SizeL;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  focused: false,
  invalid: false,
  size: 'm',
});
</script>

<template>
  <div
    :class="[
      $style.root,
      {
        [$style.disabled]: props.disabled,
        [$style.invalid]: props.invalid,
        [$style.focused]: props.focused,
        [$style.sizeS]: props.size === 's',
        [$style.sizeM]: props.size === 'm',
        [$style.sizeL]: props.size === 'l',
      },
    ]"
  >
    <div :class="$style.main">
      <slot />
    </div>
    <div v-if="hasSlotContent($slots.icon) || hasSlotContent($slots.utils)" :class="$style.utils">
      <slot name="utils" />
      <div v-if="hasSlotContent($slots.icon)" :class="$style.icon">
        <slot name="icon" />
      </div>
    </div>
    <div v-if="hasSlotContent($slots.addon)" :class="$style.addon">
      <slot name="addon" />
    </div>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/iconography';
@use '../../styles/typography';

.root {
  position: relative;
  border-radius: shared.$border-radius-s;
  min-height: 44px;
  display: flex;
  box-sizing: border-box;
  text-align: left;
  background: #fff;
  color: colors.$primary;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  font-weight: 400;

  &::after {
    @include shared.coverer();
    content: '';
    border-radius: inherit;
    border: 1px solid colors.$surface-16;
    pointer-events: none;
    box-sizing: border-box;
  }
}

.main {
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  box-sizing: border-box;
  align-items: center;
  overflow: hidden;

  > input {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    text-align: inherit;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-transform: inherit;
    border-radius: inherit;
    background: none;
    caret-color: currentColor;
    outline: none;
    appearance: none;
    word-break: keep-all;
    padding: 0 12px;

    &::placeholder {
      color: colors.$surface-40;
      white-space: pre;
    }
  }
}

.utils {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 12px;
  color: colors.$surface-40;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: iconography.$icon-size-m;
  width: 1em;
  height: 1em;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.addon {
  position: relative;
  display: flex;
  align-items: center;
  border-left: 1px solid colors.$surface-16;
}

.focused {
  &::after,
  .addon {
    border-color: #907ff5; // TODO: use token
  }
}

.invalid {
  color: colors.$status-danger;

  &::after,
  .addon {
    border-color: colors.$status-danger;
  }
}

.disabled {
  color: colors.$surface-40;
  background-color: colors.$surface-4;
}

.sizeS {
  min-height: 32px;
}
.sizeM {
  min-height: 44px;
}
.sizeL {
  min-height: 56px;
}
</style>
