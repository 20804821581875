<script lang="ts" setup>
import { computed, inject, toValue, type VNode } from 'vue';
import { IconCheck } from '@tabler/icons-vue';
import { AriaRole } from '../../shared/types';
import { Keys } from '../../shared/enums';
import { ObFragment } from '../fragment';
import { ObPrimitiveCheckbox } from '../primitive-checkbox';
import ObActionListItemContent from './ob-action-list-item-content.vue';
import { ACTION_LIST_CONTEXT } from './action-list-context';
import style from './ob-action-list-item.module.scss';

type Props = {
  role?: AriaRole;
  disabled?: boolean;
  selected?: boolean;
  indeterminate?: boolean;
};

const { role, disabled = false, selected = false, indeterminate = false } = defineProps<Props>();

const emit = defineEmits<{
  select: [];
}>();

defineSlots<{
  default?: () => VNode;
  leadingVisual?: () => VNode;
  trailingVisual?: () => VNode;
  description?: () => VNode;
}>();

const { selectionMode, compact, onAfterSelect, ...context } = inject(ACTION_LIST_CONTEXT, {});

const listRole = computed(() => toValue(context.listRole));
const withDividers = computed(() => toValue(context.withDividers));
const itemSelectionAttribute = computed(() => toValue(context.itemSelectionAttribute));
const roleAttribute = computed(() => role ?? toValue(context.itemRole));

const selectionAttribute = computed(() => {
  if (itemSelectionAttribute.value) {
    return itemSelectionAttribute.value;
  }

  if (roleAttribute.value === 'menuitemradio' || roleAttribute.value === 'menuitemcheckbox') {
    return 'aria-checked';
  }

  if (roleAttribute.value === 'option') {
    return 'aria-selected';
  }

  return undefined;
});

function onSelect() {
  emit('select');

  if (typeof onAfterSelect === 'function') {
    onAfterSelect();
  }
}

function onClick(event: MouseEvent) {
  if (disabled) {
    return;
  }

  event.preventDefault();
  onSelect();
}

function onKeydown(event: KeyboardEvent) {
  if (disabled) {
    return;
  }

  if (event.key === Keys.Space || event.key === Keys.Enter) {
    event.preventDefault();
    event.stopPropagation();
    onSelect();
  }
}

const withButton = computed(() => {
  if (listRole.value === 'listbox' || listRole.value === 'menu') {
    return false;
  }

  return true;
});

const controlAttributes = computed(() => {
  const result: Record<string, unknown> = {
    onClick,
    onKeydown,
    'aria-disabled': disabled ? true : undefined,
    disabled: withButton.value ? disabled : undefined,
    tabIndex: disabled ? undefined : '0',
    role: withButton.value ? roleAttribute.value : undefined,
    type: withButton.value ? 'button' : undefined,
    class: [
      style.control,
      {
        [style.compact]: toValue(compact),
        [style.withDivider]: !withButton.value ? withDividers.value : false,
        [style.disabled]: disabled,
      },
    ],
  };

  let inferredSelectionAttribute: 'aria-selected' | 'aria-checked' | undefined;

  if (roleAttribute.value === 'menuitemradio' || roleAttribute.value === 'menuitemcheckbox') {
    inferredSelectionAttribute = 'aria-checked';
  } else if (roleAttribute.value === 'option') {
    inferredSelectionAttribute = 'aria-selected';
  }

  const itemSelectionAttribute = toValue(selectionAttribute) || inferredSelectionAttribute;

  if (itemSelectionAttribute) {
    result[itemSelectionAttribute] = selected;
  }

  return result;
});

const wrapperAttributes = computed(() => {
  if (withButton.value) {
    return {
      class: [
        style.wrapper,
        {
          [style.withDivider]: withDividers.value,
        },
      ],
    };
  }

  return controlAttributes.value;
});
</script>

<template>
  <li v-bind="wrapperAttributes">
    <component :is="withButton ? 'button' : ObFragment" v-bind="controlAttributes">
      <span v-if="selectionMode" :class="style.leadingVisual">
        <ObPrimitiveCheckbox
          v-if="selectionMode === 'multiple'"
          :checked="selected"
          :indeterminate="indeterminate"
          :disabled="disabled"
        />
        <IconCheck v-else-if="selectionMode === 'single' && selected" :size="16" />
      </span>
      <ObActionListItemContent>
        <template #leadingVisual>
          <slot name="leadingVisual" />
        </template>
        <slot />
        <template #description>
          <slot name="description" />
        </template>
        <template #trailingVisual>
          <slot name="trailingVisual" />
        </template>
      </ObActionListItemContent>
    </component>
  </li>
</template>
