<script lang="ts" setup>
import {
  shallowRef,
  computed,
  nextTick,
  watch,
  type VNode,
  type VNodeProps,
  type ComponentPublicInstance,
} from 'vue';
import {
  useFloating,
  flip,
  autoUpdate,
  shift,
  offset,
  size,
  type Middleware,
} from '@floating-ui/vue';
import { onClickOutside, unrefElement } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useZIndex, useFocusScope } from '../../composables';
import { ObScrollableContainer } from '../scrollable-container';
import { ObUnmountableTeleportWithTransition } from '../internal';

interface Props {
  open?: boolean;
  floatingMiddleware?: (middleware: Middleware[]) => Middleware[];
}

defineOptions({
  inheritAttrs: false,
});

const { floatingMiddleware } = defineProps<Props>();

const emit = defineEmits<{
  clickOutside: [event: PointerEvent];
  activated: [];
  deactivated: [];
}>();

defineSlots<{
  default?: (props: { activate: () => void; deactivate: () => void; toggle: () => void }) => VNode;
  host?: (props: {
    hostProps: VNodeProps & { ref: (el: Element | ComponentPublicInstance | null) => void };
    open: boolean;
  }) => VNode;
}>();

const open = defineModel<boolean>('open', { default: false });

const { zIndex } = useZIndex({ active: computed(() => open.value) });

const hostRef = shallowRef();
const containerRef = shallowRef();

const middleware = computed<Middleware[]>(() => {
  let result = [
    size({
      padding: 8,
      apply({ elements, availableHeight }) {
        Object.assign(elements.floating.style, {
          maxHeight: `${availableHeight}px`,
        });
      },
    }),
    offset(8),
    flip({
      padding: 8,
    }),
    shift({
      padding: 8,
    }),
  ];

  if (typeof floatingMiddleware === 'function') {
    result = floatingMiddleware(result);
  }

  return result;
});

const { floatingStyles } = useFloating(hostRef, containerRef, {
  placement: 'bottom-start',
  middleware,
  whileElementsMounted: autoUpdate,
  open,
  transform: false,
});

const { isElementInFocusScope, active: focusZoneActive } = useFocusScope(containerRef);

const { activate: activateTrap, deactivate: deactivateTrap } = useFocusTrap(containerRef, {
  escapeDeactivates: false,
  allowOutsideClick: true,
  clickOutsideDeactivates(event) {
    return event.target ? !isElementInFocusScope(event.target as Element) : false;
  },
  returnFocusOnDeactivate: false,
  fallbackFocus: () => {
    return unrefElement(containerRef) ?? document.body;
  },
});

watch(
  open,
  (value) => {
    if (value) {
      nextTick(() => {
        activateTrap();
      });
      return;
    }

    deactivateTrap();
  },
  { immediate: true },
);

onClickOutside(
  containerRef,
  () => {
    if (!focusZoneActive.value) {
      open.value = false;
    }
  },
  { ignore: [hostRef] },
);

function onEsc(event: KeyboardEvent) {
  event.preventDefault();
  event.stopPropagation();
  open.value = false;
  nextTick(() => {
    // TODO: maybe manage with useFocusTrap config?
    hostRef.value?.focus();
  });
}

function onClick(event: MouseEvent) {
  event.preventDefault();
  open.value = !open.value;
}

function onKeydown(event: KeyboardEvent) {
  if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
    event.preventDefault();
    open.value = true;
  }
}

function activate() {
  open.value = true;
  emit('activated');
}

function deactivate() {
  open.value = false;
  nextTick(() => {
    hostRef.value?.focus();
  });
  emit('deactivated');
}

function toggle() {
  if (open.value) {
    deactivate();
    return;
  }

  activate();
}

const hostProps = computed(() => {
  return {
    ref: (el: Element | ComponentPublicInstance | null) => {
      hostRef.value = unrefElement(el as any); // TODO: how not to cast no any? Element vs HTMLElement
    },
    onKeydown,
    onClick,
    open,
  };
});
</script>

<template>
  <slot name="host" v-bind="{ hostProps, open }" />
  <ObUnmountableTeleportWithTransition
    :active="open"
    :enter-from-class="$style.enterFrom"
    :enter-active-class="$style.enterActive"
    :leave-active-class="$style.leaveActive"
    :leave-to-class="$style.leaveTo"
  >
    <div
      v-if="open"
      ref="containerRef"
      :style="{ ...floatingStyles, zIndex }"
      :class="$style.container"
      v-bind="$attrs"
      @keydown.esc="onEsc"
    >
      <ObScrollableContainer light>
        <slot v-bind="{ activate, deactivate, toggle }" />
      </ObScrollableContainer>
    </div>
  </ObUnmountableTeleportWithTransition>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.enterActive,
.leaveActive {
  transition-property: opacity, transform;
  transition-duration: 0.2s;
}

.enterActive {
  transition-timing-function: ease-out;
}

.leaveActive {
  transition-timing-function: ease-in;
}

.enterFrom,
.leaveTo {
  opacity: 0;
  transform: scale(0.9);
}

.container {
  box-sizing: border-box;
  height: auto;
  width: auto;
  border-radius: 12px;
  background: #fff;
  color: colors.$primary;
  border: 1px solid colors.$surface-6;
  box-shadow: 0px 0px 18px 0px rgba(2, 17, 72, 0.2);
  font-family: typography.$font-family-primary;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>
