export enum Stacks {
  ModalOverlay = 'modal_overlay',
  SidePages = 'sidepages',
}

// https://www.w3.org/TR/uievents-key/#named-key-attribute-values
export enum Keys {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Backspace = 'Backspace',
  Delete = 'Delete',
  End = 'End',
  Enter = 'Enter',
  Escape = 'Escape',
  Home = 'Home',
  PageDown = 'PageDown',
  PageUp = 'PageUp',
  Space = ' ',
  Tab = 'Tab',
}
