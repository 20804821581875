<script lang="ts" setup>
import { IconUpload, IconFileDescription, IconCircleX, IconCircleCheck } from '@tabler/icons-vue';
import { ref, computed } from 'vue';
import { useFileDialog } from '@vueuse/core';
import { useConfig } from '../../composables';
import { createUniqueId } from '../../utils';
import { ObFlexGrid, ObFlexGridItem } from '../flex-grid';
import { ObLink } from '../link';
import { ObSpace } from '../space';
import { FileUploaderUploading } from './types';

interface Props {
  uploadHandler: (uploading: FileUploaderUploading) => void;
  abortHandler?: (uploading?: FileUploaderUploading) => Promise<boolean> | boolean;
  removeHandler?: (uploading?: FileUploaderUploading) => Promise<boolean> | boolean;
  onValidationFailed?: (file: File) => void;
  filter?: (files: FileList) => FileList;
  accept: string;
  multiple?: boolean;
  disabled?: boolean;
  maxFileSize?: number;
  labelTrigger?: string;
}

const props = withDefaults(defineProps<Props>(), {
  uploadHandler: undefined,
  abortHandler: undefined,
  removeHandler: undefined,
  onValidationFailed: undefined,
  filter: undefined,
  accept: '',
  multiple: false,
  maxFileSize: undefined,
  disabled: false,
  labelTrigger: undefined,
});

const emit = defineEmits<{
  uploadCompleted: [uploadInfo: FileUploaderUploading['uploadInfo']];
  uploadAborted: [uploadInfo: FileUploaderUploading['uploadInfo']];
  uploadRemoved: [uploadInfo: FileUploaderUploading['uploadInfo']];
  uploadError: [uploadInfo: FileUploaderUploading['uploadInfo']];
}>();

const uploadings = ref<FileUploaderUploading[]>([]);
const dragActive = ref(false);

function createUploading(file: File) {
  const controller = new AbortController();
  const id = createUniqueId();

  if (props.maxFileSize && file.size > props.maxFileSize) {
    props.onValidationFailed?.(file);
    return false;
  }
  const uploadInfo = ref<FileUploaderUploading['uploadInfo']>({
    id,
    state: 'uploading',
    progress: 0,
    file,
    result: undefined,
    errorMessage: '',
    signal: controller.signal,
  });

  function abort() {
    uploadInfo.value.state = 'aborted';
    controller.abort();
  }

  function setError(errorMessage: string | undefined) {
    uploadInfo.value.state = 'error';
    if (errorMessage) {
      uploadInfo.value.errorMessage = errorMessage;
    }
    emit('uploadError', uploadInfo.value);
  }

  function setSuccess(result: any) {
    uploadInfo.value.state = 'completed';
    uploadInfo.value.result = result;
    emit('uploadCompleted', uploadInfo.value);
  }

  function setProgress(progress: number) {
    uploadInfo.value.progress = progress;
  }

  function retry() {
    uploadInfo.value.state = 'uploading';
    uploadInfo.value.progress = 0;
    uploadInfo.value.errorMessage = '';
    uploadInfo.value.result = undefined;

    props.uploadHandler({
      uploadInfo: uploadInfo.value,
      abort,
      setError,
      setSuccess,
      setProgress,
      retry,
    });
  }
  return { uploadInfo: uploadInfo.value, abort, setError, setSuccess, setProgress, retry };
}

function upload(files: FileList) {
  let filteredFiles;

  if (props.filter) {
    filteredFiles = props.filter(files);
  }

  Object.entries(filteredFiles || files).map(([, file]: [string, File]) => {
    const uploading = createUploading(file);
    if (uploading) {
      uploadings.value.push(uploading);
      props.uploadHandler(uploading);
    }
  });
}

async function abortUpload(uploading: FileUploaderUploading) {
  if (props.abortHandler) {
    const result = await props.abortHandler(uploading);
    if (result === false) {
      return;
    }
  }
  uploading.abort();
  uploadings.value = uploadings.value.filter(
    (uploadingDetails) => uploadingDetails.uploadInfo.id !== uploading.uploadInfo.id,
  );

  emit('uploadAborted', uploading.uploadInfo);
}

async function removeUpload(uploading: FileUploaderUploading) {
  if (props.removeHandler) {
    const result = await props.removeHandler(uploading);
    if (result === false) {
      return;
    }
  }
  uploadings.value = uploadings.value.filter(
    (uploadingDetails) => uploadingDetails.uploadInfo.id !== uploading.uploadInfo.id,
  );

  emit('uploadRemoved', uploading.uploadInfo);
}

function handleDragEvent(event: DragEvent) {
  if (props.disabled) {
    return;
  }

  if (event.dataTransfer) {
    upload(event.dataTransfer.files);
  }
}

const { open: openFileDialog, onChange } = useFileDialog({
  accept: props.accept,
  multiple: props.multiple,
  directory: false,
  reset: true,
});

onChange((files) => {
  if (!files) {
    return;
  }
  upload(files);
});

const config = useConfig();

const labelTrigger = computed(() => {
  return (
    props.labelTrigger ??
    config?.value?.i18n?.FileUploader?.labelTrigger ??
    'Click to upload or drag and drop'
  );
});
</script>

<template>
  <ObFlexGrid direction="column" spacing-y="5">
    <ObFlexGridItem
      :class="[
        $style.uploadBox,
        { [$style.uploadBoxDrag]: dragActive },
        { [$style.uploadBoxDisable]: disabled },
      ]"
      @drop.prevent="handleDragEvent"
      @dragover.prevent="dragActive = true"
      @dragleave.prevent="dragActive = false"
    >
      <div :class="$style.content">
        <ObSpace spacing="4" vertical align-x="center">
          <div :class="[$style.icon, $style.uploadIcon]">
            <IconUpload />
          </div>
          <div>
            <ObLink v-slot="{ rootProps }" as-child :disabled="disabled">
              <button v-bind="rootProps" type="button" @click="openFileDialog()">
                {{ labelTrigger }}
              </button>
            </ObLink>
          </div>
        </ObSpace>
        <div :class="$style.text">
          <slot name="text" />
        </div>
      </div>
    </ObFlexGridItem>
    <ObFlexGridItem
      v-for="uploading in uploadings"
      :key="uploading.uploadInfo.id"
      :class="[
        $style.filesSelectionBox,
        { [$style.filesSelectionBoxError]: uploading.uploadInfo.state === 'error' },
      ]"
    >
      <ObFlexGrid spacing-x="5">
        <ObFlexGridItem size="auto">
          <div
            :class="[
              $style.icon,
              $style.fileIcon,
              { [$style.fileIconError]: uploading.uploadInfo.state === 'error' },
            ]"
          >
            <IconFileDescription />
          </div>
        </ObFlexGridItem>
        <ObFlexGridItem>
          <ObFlexGrid direction="column">
            <ObFlexGridItem size="auto">
              <ObFlexGrid direction="row">
                <ObFlexGridItem :class="$style.fileHeader">
                  <div v-if="uploading.uploadInfo.state === 'error'">
                    {{ uploading.uploadInfo.errorMessage || 'Upload failed, please try again' }}
                  </div>
                  <div v-else>
                    {{ uploading.uploadInfo?.file.name }}
                  </div>
                </ObFlexGridItem>
                <ObFlexGridItem size="auto" :class="$style.iconItem">
                  <IconCircleX
                    v-if="uploading.uploadInfo.state === 'uploading'"
                    :class="$style.iconCircleX"
                    @click="abortUpload(uploading)"
                  />
                  <IconCircleX
                    v-else-if="uploading.uploadInfo.state === 'error'"
                    :class="$style.iconCircleX"
                    @click="removeUpload(uploading)"
                  />
                  <IconCircleCheck
                    v-else-if="uploading.uploadInfo.state === 'completed'"
                    :class="$style.iconCircleCheck"
                  />
                </ObFlexGridItem>
              </ObFlexGrid>
            </ObFlexGridItem>
            <ObFlexGridItem :class="$style.fileDetails" size="auto">
              <div v-if="uploading.uploadInfo.state === 'error'">
                {{ uploading.uploadInfo?.file.name }}
              </div>
              <div v-else>{{ uploading.uploadInfo.file.size / 1000 }} KB</div></ObFlexGridItem
            >
            <ObFlexGridItem size="auto">
              <ObFlexGrid v-if="uploading.uploadInfo.state !== 'error'" align-items="center">
                <ObFlexGridItem :class="$style.progressBar">
                  <div
                    :class="$style.progress"
                    :style="{ width: Math.ceil(uploading.uploadInfo?.progress * 100) + '%' }"
                  />
                </ObFlexGridItem>
                <ObFlexGridItem size="auto">
                  <div>{{ Math.ceil(uploading.uploadInfo?.progress * 100) }}%</div>
                </ObFlexGridItem>
              </ObFlexGrid>
              <ObLink v-else v-slot="{ rootProps }" as-child>
                <button v-bind="rootProps" type="button" @click="uploading.retry()">
                  Try again
                </button>
              </ObLink>
            </ObFlexGridItem>
          </ObFlexGrid>
        </ObFlexGridItem>
      </ObFlexGrid>
    </ObFlexGridItem>
  </ObFlexGrid>
</template>

<style lang="scss" module>
@use '../../styles/colors';

.uploadBox {
  display: flex;
  padding: 96px 24px;
  flex-direction: column;
  border-radius: 12px;
  border: 1px dashed colors.$surface-16;
  background: #fff;
}

.uploadBoxDrag {
  background: #f8f7fe; // TODO: use token
  border: 1px dashed #907ff5;
}
.uploadBoxDisable {
  background: colors.$surface-6; // TODO: use token
  border: 1px solid colors.$surface-16;
}

.icon {
  display: flex;
  padding: 10px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(2, 17, 72, 0.14); // TODO: use design token
}

.uploadIcon {
  color: #7a68e3; // TODO: token
}

.fileIcon {
  color: #9aa0b6;
}

.fileIconError {
  color: colors.$status-danger;
  background: rgba(228, 40, 40, 0.1);
}

.iconItem {
  height: 24px;
}

.iconCircleCheck {
  color: #907ff5; // TODO: design token
}

.iconCircleX {
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
}

.text {
  font-size: 12px;
  line-height: 18px;
}

.fileHeader {
  font-size: 14px;
  line-height: 20px;
}

.fileDetails {
  font-size: 12px;
  line-height: 18px;
}

.filesSelectionBox {
  border-radius: 12px;
  border: 1px solid colors.$surface-16;
  background: #fff;
  padding: 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.filesSelectionBoxError {
  border: 1px solid colors.$status-danger;
  background: #fcf6f7;
}

.progressBar {
  height: 8px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  background: colors.$surface-6;
  margin-right: 14px;
}

.progress {
  height: 100%;
  background-color: #907ff5; // TODO: token
  transition: width 0.1s ease;
}
</style>
