import { ComputedRef, InjectionKey } from 'vue';

type BatchActionsMenuContext = {
  verticalItemsLayout: ComputedRef<boolean>;
  register: (id: string) => void;
  unregister: (id: string) => void;
};

export const BATCH_ACTIONS_MENU_CONTEXT: InjectionKey<BatchActionsMenuContext> = Symbol(
  __DEV__ ? 'BatchActionsMenuContext' : '',
);
