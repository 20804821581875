<script lang="ts" setup>
import { computed, ref, toRef } from 'vue';
import { useVModel, useFocus, unrefElement, syncRef } from '@vueuse/core';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-vue';
import type { SizeS, SizeM, SizeL } from '../../shared/types';
import { DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR } from '../../shared/constants';
import { useInputNumber, useConfig } from '../../composables';
import { ObPrimitiveInput } from '../primitive-input';
import { ObDecoratedInput } from '../decorated-input';

type Value = number | null;

interface Props {
  decimal?: 'never' | 'always' | 'not-zero';
  decimalSeparator?: string;
  disabled?: boolean;
  exponential?: number;
  invalid?: boolean;
  max?: number;
  min?: number;
  modelValue?: Value;
  placeholder?: string;
  postfix?: string;
  prefix?: string;
  precision?: number;
  readonly?: boolean;
  size?: SizeS | SizeM | SizeL;
  step?: number;
  tabindex?: number;
  thousandSeparator?: string;
  incrementHandler?: (value: Value) => Value;
  decrementHandler?: (value: Value) => Value;
}

const props = withDefaults(defineProps<Props>(), {
  decimal: 'not-zero',
  decimalSeparator: undefined,
  disabled: false,
  exponential: 0.5,
  invalid: false,
  max: Number.MAX_SAFE_INTEGER,
  min: Number.MIN_SAFE_INTEGER,
  modelValue: null,
  placeholder: '',
  postfix: '',
  prefix: '',
  precision: 2,
  readonly: false,
  size: 'm',
  step: 1,
  tabindex: undefined,
  thousandSeparator: undefined,
  incrementHandler: undefined,
  decrementHandler: undefined,
});

const emit = defineEmits<{
  'update:modelValue': [value: number | null];
}>();

const config = useConfig();

const decimalSeparator = computed<string>(
  () =>
    props.decimalSeparator ||
    config?.value?.numberFormat?.decimalSeparator ||
    DEFAULT_DECIMAL_SEPARATOR,
);
const thousandSeparator = computed<string>(
  () =>
    props.thousandSeparator ??
    config?.value?.numberFormat?.thousandSeparator ??
    DEFAULT_THOUSAND_SEPARATOR,
);
const min = toRef(props, 'min');
const max = toRef(props, 'max');
const decimal = toRef(props, 'decimal');
const precision = toRef(props, 'precision');

const inputRef = ref();

const modelValue = useVModel(props, 'modelValue', emit, { passive: true, defaultValue: null });

const { inputValue, modelValue: inputModelValue } = useInputNumber(inputRef, {
  decimalSeparator,
  thousandSeparator,
  min,
  max,
  decimal,
  precision,
  initialValue: modelValue.value,
});

syncRef(inputModelValue, modelValue);

const inputMode = computed(() => (props.decimal === 'never' ? 'numeric' : 'decimal'));

const { focused } = useFocus(inputRef);

function onWrapperMouseDown(event: MouseEvent) {
  if (event.target === unrefElement(inputRef)) {
    return;
  }

  event.preventDefault();
  inputRef.value?.focus();
}

function normalizeValue(value: unknown): number | null {
  if (typeof value === 'number') {
    return Math.min(Math.max(value, props.min), props.max);
  }

  return null;
}

function increment(): void {
  let newValue = modelValue.value;

  if (typeof props.incrementHandler === 'function') {
    newValue = props.incrementHandler(modelValue.value);
  } else {
    newValue = modelValue.value === null ? props.step : modelValue.value + props.step;
  }

  modelValue.value = normalizeValue(newValue);
}

function decrement(): void {
  let newValue = modelValue.value;

  if (typeof props.decrementHandler === 'function') {
    newValue = props.decrementHandler(modelValue.value);
  } else {
    newValue = modelValue.value === null ? props.min : modelValue.value - props.step;
  }

  modelValue.value = normalizeValue(newValue);
}

let longPressTimeout: ReturnType<typeof setTimeout>;
let timesPressed = 1;

function longPressTick(incrementing = true): void {
  if (incrementing) {
    increment();
  } else {
    decrement();
  }

  longPressTimeout = longPressTimeout = setTimeout(
    () => {
      longPressTick(incrementing);
    },
    props.exponential ? 250 / (props.exponential * timesPressed++) : 250,
  );
}

function startLongPress(incrementing = true): void {
  clearTimeout(longPressTimeout);
  longPressTick(incrementing);
}

function stopLongPress(): void {
  clearTimeout(longPressTimeout);
  timesPressed = 1;
}

function onArrowDown(event: KeyboardEvent): void {
  event.preventDefault();
  decrement();
}

function onArrowUp(event: KeyboardEvent): void {
  event.preventDefault();
  increment();
}

function onUpButtonMouseDown(event: MouseEvent): void {
  event.preventDefault();
  inputRef.value?.focus();
  startLongPress();
}

function onDownButtonMouseDown(event: MouseEvent): void {
  event.preventDefault();
  inputRef.value?.focus();
  startLongPress(false);
}
</script>

<template>
  <ObPrimitiveInput
    :size="props.size"
    :disabled="props.disabled"
    :invalid="props.invalid"
    :focused="focused"
    @mousedown="onWrapperMouseDown"
  >
    <div :class="$style.root">
      <ObDecoratedInput
        :prefix="props.prefix"
        :postfix="props.postfix"
        :placeholder="props.placeholder"
        :value="inputValue"
      >
        <input
          ref="inputRef"
          :value="inputValue"
          autocomplete="off"
          :aria-invalid="props.invalid"
          :disabled="props.disabled"
          :inputmode="inputMode"
          :readonly="props.readonly"
          :tabindex="props.tabindex"
          type="text"
          @keydown.down.stop="onArrowDown"
          @keydown.up.stop="onArrowUp"
        />
      </ObDecoratedInput>
    </div>
    <template #icon>
      <slot name="icon" />
    </template>
    <template #addon>
      <div
        :class="[
          $style.controls,
          {
            [$style.sizeL]: props.size === 'l',
          },
        ]"
      >
        <button
          type="button"
          tabindex="-1"
          :class="$style.button"
          @mousedown="onUpButtonMouseDown"
          @mouseup="stopLongPress()"
          @mouseleave="stopLongPress()"
        >
          <IconChevronUp aria-hidden="true" :size="16" />
        </button>
        <button
          type="button"
          tabindex="-1"
          :class="$style.button"
          @mousedown.prevent="onDownButtonMouseDown"
          @mouseup="stopLongPress()"
          @mouseleave="stopLongPress()"
        >
          <IconChevronDown aria-hidden="true" :size="16" />
        </button>
      </div>
    </template>
  </ObPrimitiveInput>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';

.root {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  padding: 0 12px;
}

.cleaner {
  @include shared.reset-button();
  display: flex;
  color: inherit;
  font-size: 24px;
  width: 1em;
  height: 1em;
  cursor: pointer;
}

.controls {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 4px;
  width: 44px;
  height: 100%;
}

.button {
  @include shared.reset-button();
  display: flex;
  width: 100%;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  color: colors.$surface-40;
  border-radius: shared.$border-radius-s;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;

  &:hover {
    background-color: #f8f7fe; // TODO: use token
  }

  &:active {
    background-color: #907ff5; // TODO: use token
    color: colors.$white;
  }
}

.button + .button {
  margin-top: 2px;
}

.sizeL .button + .button {
  margin-top: 4px;
}
</style>
