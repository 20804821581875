import type { ComputedRef, InjectionKey } from 'vue';
import type { Config } from './types';

export const defaultConfig: Config = {
  numberFormat: {
    decimalSeparator: '.',
    thousandSeparator: ',',
  },
};

export const CONFIG_INJECTION_KEY: InjectionKey<ComputedRef<Config>> = Symbol(
  __DEV__ ? 'Config' : '',
);
