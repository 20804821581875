<script lang="ts" setup>
import { ref, useSlots, computed, nextTick, watchEffect, watch } from 'vue';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { unrefElement, refAutoReset, useVModel } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { IconX } from '@tabler/icons-vue';
import { useStack, useZIndex, useTrueSelfClick } from '../../composables';
import { ObModalOverlay } from '../modal-overlay';
import { hasSlotContent } from '../../utils';
import { MODALS_STACK_NAME } from './shared';

// TODO: fix focus return on menu close

interface Props {
  active?: boolean;
  closeButton?: boolean;
  deactivateOnEscapePress?: boolean;
  deactivateOnOverlayClick?: boolean;
  minHeight?: CSSStyleDeclaration['minHeight'];
  onBeforeDeactivate?: () => Promise<void | boolean> | void | boolean;
  title?: string;
  width?: CSSStyleDeclaration['width'];
}

const props = withDefaults(defineProps<Props>(), {
  active: false,
  closeButton: true,
  deactivateOnEscapePress: true,
  deactivateOnOverlayClick: true,
  minHeight: undefined,
  onBeforeDeactivate: undefined,
  title: undefined,
  width: undefined,
});

const emit = defineEmits<{
  'update:active': [active: boolean];
  activated: [];
  deactivated: [];
}>();

const active = useVModel(props, 'active', emit, {
  passive: true,
});
const portalActive = ref(false);

watchEffect(() => {
  if (active.value) {
    portalActive.value = true;
  }
});

const rootRef = ref();

const { zIndex } = useZIndex({ active: portalActive, size: 2 });

const { activate: activateTrap, deactivate: deactivateTrap } = useFocusTrap(rootRef, {
  escapeDeactivates: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: false,
  returnFocusOnDeactivate: true,
});

const { last } = useStack(MODALS_STACK_NAME, {
  active: portalActive,
});

const shaking = refAutoReset(false, 1000);

function activate() {
  active.value = true;
  emit('activated');
}

function deactivate() {
  active.value = false;
  emit('deactivated');
}

async function tryDeactivate() {
  if (typeof props.onBeforeDeactivate === 'function') {
    const allowed = await props.onBeforeDeactivate();

    if (allowed === false) {
      shaking.value = true;
      return;
    }
  }

  deactivate();
}

function onEscapePress() {
  if (!props.deactivateOnEscapePress) {
    return;
  }
  tryDeactivate();
}

const innerRef = ref();

useTrueSelfClick(innerRef, () => {
  if (!props.deactivateOnOverlayClick) {
    return;
  }
  tryDeactivate();
});

watch(
  active,
  (value, oldValue) => {
    if (value) {
      nextTick(() => {
        if (!active.value) {
          return;
        }

        activateTrap();
        disableBodyScroll(unrefElement(rootRef), {
          reserveScrollBarGap: true,
        });
      });
      return;
    }

    if (typeof oldValue === 'undefined') {
      return;
    }

    deactivateTrap();
    enableBodyScroll(unrefElement(rootRef));
  },
  { immediate: true },
);

const $slots = useSlots();
const hasTitle = computed(() => props.title || hasSlotContent($slots.title));
const hasHeader = computed(() => props.closeButton || hasTitle.value);

const boxStyles = computed(() => ({
  minHeight: props.minHeight,
  width: props.width,
}));

const sharedSlotScope = { active, activate, deactivate };
</script>

<template>
  <slot name="activator" v-bind="sharedSlotScope" />
  <Teleport v-if="portalActive" to="body">
    <Transition
      appear
      :enter-from-class="$style.transitionEnterFrom"
      :enter-active-class="$style.transitionEnterActive"
      :leave-active-class="$style.transitionLeaveActive"
      :leave-to-class="$style.transitionLeaveTo"
      @after-leave="portalActive = false"
    >
      <div
        v-if="active"
        ref="rootRef"
        :class="[$style.root, { [$style.shakeAnimation]: shaking }]"
        role="dialog"
        aria-modal="true"
        :style="{ zIndex: zIndex && zIndex + 1 }"
        tabindex="-1"
        @keydown.esc.stop.capture="onEscapePress"
      >
        <div ref="innerRef" :class="$style.inner">
          <div :class="$style.box" :style="boxStyles">
            <header v-if="hasHeader" :class="$style.header">
              <div v-if="hasTitle" :class="$style.title">
                <slot name="title">{{ props.title }}</slot>
              </div>
              <button
                v-if="props.closeButton"
                type="button"
                :class="$style.close"
                aria-label="Close"
                @click="tryDeactivate()"
              >
                <IconX aria-hidden="true" />
              </button>
            </header>
            <div :class="$style.body">
              <slot v-bind="sharedSlotScope" />
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <ObModalOverlay v-if="last" :z-index="zIndex" />
  </Teleport>
</template>

<style lang="scss" module>
@use '../../styles/shared';
@use '../../styles/colors';
@use '../../styles/typography';

.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  will-change: opacity, transform;
  outline: none;
  font-family: typography.$font-family-primary;
  scrollbar-gutter: stable;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  min-height: 100%;
  box-sizing: border-box;
}

.box {
  position: relative;
  z-index: 200;
  box-sizing: border-box;
  background: colors.$white;
  color: colors.$primary;
  padding: 24px;
  margin: auto;
  border-radius: shared.$border-radius-m;
  width: 360px;
  max-width: 100%;
  box-shadow: 0px 0px 18px rgba(2, 17, 72, 0.2); // TODO: use token
}

.header {
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.title {
  font-size: 18px;
  line-height: 24px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
}

.close {
  @include shared.reset-button();
  flex: none;
  margin-left: 8px;
  display: flex;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(5px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(0, 0, 0);
  }
}

.shakeAnimation {
  animation: shake 1s linear 0.2s infinite;
}

.transitionEnterFrom,
.transitionLeaveTo {
  opacity: 0.5;
  transform: scale(0.9);
}

.transitionEnterActive,
.transitionLeaveActive {
  transition-property: opacity, transform;
  overflow: hidden;
}

.transitionEnterActive {
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.transitionLeaveActive {
  transition-duration: 0.15s;
  transition-timing-function: ease-in;
}
</style>
