import type { InjectionKey, MaybeRefOrGetter } from 'vue';
import type { AriaRole } from '../../shared/types';

interface ActionListContext {
  listRole?: MaybeRefOrGetter<AriaRole | undefined>;
  selectionMode?: MaybeRefOrGetter<'single' | 'multiple' | undefined>;
  itemSelectionAttribute?: MaybeRefOrGetter<'aria-selected' | 'aria-checked' | undefined>;
  itemRole?: MaybeRefOrGetter<AriaRole | undefined>;
  compact?: MaybeRefOrGetter<boolean | undefined>;
  withDividers?: MaybeRefOrGetter<boolean | undefined>;
  onAfterSelect?: () => void;
}

export const ACTION_LIST_CONTEXT: InjectionKey<ActionListContext> = Symbol(
  __DEV__ ? 'ActionList context' : '',
);
