<script lang="ts" setup generic="T">
import { provide, computed } from 'vue';
import { AriaRole } from '../../shared/types';
import { ACTION_LIST_CONTEXT } from './action-list-context';

interface Props {
  listRole?: AriaRole;
  itemRole?: AriaRole;
  itemSelectionAttribute?: 'aria-selected' | 'aria-checked';
  selectionMode?: 'single' | 'multiple';
  compact?: boolean;
  withDividers?: boolean;
  onAfterSelect?: () => void;
}

const {
  listRole,
  itemRole,
  itemSelectionAttribute,
  selectionMode,
  compact,
  withDividers,
  onAfterSelect,
} = defineProps<Props>();

provide(ACTION_LIST_CONTEXT, {
  listRole: computed(() => listRole),
  itemRole: computed(() => itemRole),
  itemSelectionAttribute: computed(() => itemSelectionAttribute),
  selectionMode: computed(() => selectionMode),
  compact: computed(() => compact),
  withDividers: computed(() => withDividers),
  onAfterSelect,
});
</script>

<template>
  <slot />
</template>
