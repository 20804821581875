<script lang="ts" setup>
import { IconX } from '@tabler/icons-vue';
import { Keys } from '../../shared/enums';

interface Props {
  interactive?: boolean;
  removable?: boolean;
}

const { interactive = false, removable = false } = defineProps<Props>();

const emit = defineEmits<{
  remove: [];
}>();

function onKeydown(event: KeyboardEvent) {
  if (event.key === Keys.Backspace || event.key === Keys.Delete) {
    emit('remove');
  }
}
</script>

<template>
  <span
    :class="[$style.root, { [$style.interactive]: interactive }]"
    :tabindex="interactive ? '0' : undefined"
    @keydown="onKeydown"
  >
    <span :class="$style.text"><slot /></span>
    <!-- TODO: <span>(press backspace or delete to remove)</span> -->
    <span
      v-if="removable"
      :tabindex="interactive ? -1 : undefined"
      :aria-hidden="interactive ? 'true' : undefined"
      :aria-label="interactive ? undefined : 'Remove'"
      :class="$style.remover"
      @click.prevent.stop="emit('remove')"
    >
      <span :class="$style.removerIcon">
        <IconX aria-hidden="true" />
      </span>
    </span>
  </span>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  color: colors.$primary-80;
  border: 1px solid #b4b7e8; // TODO: design token
  background: #f8f7fe; // TODO: design token
  padding: 0 4px;
  height: 20px;
  border-radius: shared.$border-radius-s;
  max-width: 144px;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}

.text {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  font-family: typography.$font-family-primary;
  flex: 1 1 auto;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remover {
  @include shared.reset-button();
  display: flex;
  align-items: center;
  flex: none;
  height: 100%;
  margin-left: 4px;
  margin-right: -4px;
  padding-right: 4px;

  &:hover {
    opacity: 0.75;
  }

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}

.removerIcon {
  font-size: 14px;
  width: 1em;
  height: 1em;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.interactive {
  cursor: pointer;

  &:hover {
    background: #f2f0fe; // TODO: design token
  }

  &:active {
    background: #dad4fc; // TODO: design token
  }
}
</style>
