<script lang="ts" setup>
import {
  IconAlertCircle,
  IconDotsCircleHorizontal,
  IconCircleCheck,
  IconCircleX,
  IconInfoCircle,
  IconX,
  IconLoader2,
} from '@tabler/icons-vue';
import type { Status } from '../../shared/types';
import { ObSpace } from '../space';

interface Props {
  closable?: boolean;
  loading?: boolean;
  variant?: Status | null;
}

const { closable = false, loading = false, variant } = defineProps<Props>();

const emit = defineEmits<{
  close: [];
}>();
</script>

<template>
  <div
    :class="[
      $style.alert,
      {
        [$style.alertInfo]: variant === 'info',
        [$style.alertWarning]: variant === 'warning',
        [$style.alertDanger]: variant === 'danger',
        [$style.alertSuccess]: variant === 'success',
      },
    ]"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div :class="$style.content">
      <div :class="[$style.icon, { [$style.iconLoading]: loading }]">
        <IconLoader2 v-if="loading" aria-hidden="true" />
        <slot v-else name="icon">
          <IconInfoCircle v-if="variant === 'info'" aria-hidden="true" />
          <IconAlertCircle v-else-if="variant === 'warning'" aria-hidden="true" />
          <IconCircleX v-else-if="variant === 'danger'" aria-hidden="true" />
          <IconCircleCheck v-else-if="variant === 'success'" aria-hidden="true" />
          <IconDotsCircleHorizontal v-else aria-hidden="true" />
        </slot>
      </div>
      <div :class="$style.text">
        <slot />
      </div>
    </div>
    <div :class="$style.buttons">
      <ObSpace align-y="center" spacing="5">
        <slot name="buttons" />
        <button
          v-if="closable"
          type="button"
          aria-label="Close"
          :class="$style.closeButton"
          @click="emit('close')"
        >
          <IconX aria-hidden="true" />
        </button>
      </ObSpace>
    </div>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/typography';
@use '../../styles/shared';
@use '../../styles/elevation';
@use '../../styles/iconography';

.alert {
  @include elevation.shadow(3);
  border-radius: shared.$border-radius-s;
  background: colors.$primary-80;
  color: colors.$white;
  padding: 12px;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
}

.alertInfo {
  background: colors.$status-info;
}

.alertWarning {
  background: colors.$status-warning;
  color: colors.$primary;
}

.alertDanger {
  background: colors.$status-danger;
}

.alertSuccess {
  background: colors.$status-success;
}

.content {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  padding: 4px 0;
}

.icon {
  display: inline-flex;
  font-size: iconography.$icon-size-m;
  width: 1em;
  height: 1em;
  margin-right: 8px;
  color: currentColor;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.iconLoading {
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.buttons {
  margin-left: 16px;
}

.closeButton {
  @include shared.reset-button();
  display: inline-flex;
  font-size: 24px;
  height: 32px;
  align-items: center;
  color: currentColor;
}
</style>
