<script lang="ts" setup>
import { computed, ref, toRef } from 'vue';
import { useVModel, useFocus, unrefElement, syncRef } from '@vueuse/core';
import { IconX } from '@tabler/icons-vue';
import type { SizeS, SizeM, SizeL } from '../../shared/types';
import { DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR } from '../../shared/constants';
import { useInputNumber, useConfig } from '../../composables';
import { ObPrimitiveInput } from '../primitive-input';
import { ObDecoratedInput } from '../decorated-input';

interface Props {
  clearable?: boolean;
  decimal?: 'never' | 'always' | 'not-zero';
  decimalSeparator?: string;
  disabled?: boolean;
  filler?: string;
  invalid?: boolean;
  max?: number;
  min?: number;
  modelValue?: number | null;
  placeholder?: string;
  postfix?: string;
  prefix?: string;
  precision?: number;
  readonly?: boolean;
  size?: SizeS | SizeM | SizeL;
  tabindex?: number;
  thousandSeparator?: string;
}

const props = withDefaults(defineProps<Props>(), {
  clearable: false,
  decimal: 'not-zero',
  decimalSeparator: undefined,
  disabled: false,
  filler: '',
  invalid: false,
  max: Number.MAX_SAFE_INTEGER,
  min: Number.MIN_SAFE_INTEGER,
  modelValue: null,
  placeholder: '',
  postfix: '',
  prefix: '',
  precision: 2,
  readonly: false,
  size: 'm',
  tabindex: undefined,
  thousandSeparator: undefined,
});

const emit = defineEmits<{
  clear: [];
  'update:modelValue': [value: number | null];
}>();

const config = useConfig();

const decimalSeparator = computed<string>(
  () =>
    props.decimalSeparator ||
    config?.value?.numberFormat?.decimalSeparator ||
    DEFAULT_DECIMAL_SEPARATOR,
);
const thousandSeparator = computed<string>(
  () =>
    props.thousandSeparator ??
    config?.value?.numberFormat?.thousandSeparator ??
    DEFAULT_THOUSAND_SEPARATOR,
);
const min = toRef(props, 'min');
const max = toRef(props, 'max');
const decimal = toRef(props, 'decimal');
const precision = toRef(props, 'precision');

const inputRef = ref();

const modelValue = useVModel(props, 'modelValue', emit, { passive: true, defaultValue: null });

const { inputValue, modelValue: inputModelValue } = useInputNumber(inputRef, {
  decimalSeparator,
  thousandSeparator,
  min,
  max,
  decimal,
  precision,
  initialValue: modelValue.value,
});

syncRef(inputModelValue, modelValue);

const inputMode = computed(() => (props.decimal === 'never' ? 'numeric' : 'decimal'));

const { focused } = useFocus(inputRef);

function clear(): void {
  modelValue.value = null;
  emit('clear');
}

function onWrapperMouseDown(event: MouseEvent) {
  if (event.target === unrefElement(inputRef)) {
    return;
  }

  event.preventDefault();
  inputRef.value?.focus();
}
</script>

<template>
  <ObPrimitiveInput
    :size="props.size"
    :disabled="props.disabled"
    :invalid="props.invalid"
    :focused="focused"
    @mousedown="onWrapperMouseDown"
  >
    <div :class="$style.root">
      <ObDecoratedInput
        :prefix="props.prefix"
        :postfix="props.postfix"
        :placeholder="props.placeholder"
        :filler="props.filler"
        :value="inputValue"
      >
        <input
          ref="inputRef"
          :value="inputValue"
          autocomplete="off"
          :aria-invalid="props.invalid"
          :disabled="props.disabled"
          :inputmode="inputMode"
          :readonly="props.readonly"
          :tabindex="props.tabindex"
          type="text"
        />
      </ObDecoratedInput>
    </div>
    <template #utils>
      <button
        v-if="props.clearable && modelValue !== null"
        type="button"
        :class="$style.cleaner"
        tabindex="-1"
        aria-label="Clear"
        @click="clear()"
      >
        <IconX aria-hidden="true" />
      </button>
    </template>
    <template #icon>
      <slot name="icon" />
    </template>
    <template #addon>
      <slot name="addon" />
    </template>
  </ObPrimitiveInput>
</template>

<style lang="scss" module>
@use '../../styles/shared';

.root {
  display: flex;
  height: 100%;
  padding: 0 12px;
  box-sizing: border-box;
}

.cleaner {
  @include shared.reset-button();
  display: flex;
  color: inherit;
  font-size: 24px;
  width: 1em;
  height: 1em;
  cursor: pointer;
}
</style>
