import type { InjectionKey, Ref } from 'vue';

interface AbstractDisclosureContext {
  expanded: Ref<boolean>;
  expand: () => void;
  collapse: () => void;
  toggle: () => void;
  buttonId: Ref<string | null>;
  panelId: Ref<string | null>;
  buttonRef: Ref<HTMLElement | null>;
  panelRef: Ref<HTMLElement | null>;
}

type AbstractDisclosurePanelContext = Ref<string | null>;

export const ABSTRACT_DISCLOSURE_CONTEXT: InjectionKey<AbstractDisclosureContext> = Symbol(
  __DEV__ ? 'AbstractDisclosureContext' : '',
);

export const ABSTRACT_DISCLOSURE_PANEL_CONTEXT: InjectionKey<AbstractDisclosurePanelContext> =
  Symbol(__DEV__ ? 'AbstractDisclosurePanelContext' : '');
