import { ObjectDirective, DirectiveBinding } from 'vue';

import { setNativeFocused } from '../../utils';

type FocusDirectiveBinding = DirectiveBinding<boolean>;

const handle = (el: HTMLElement, binding: DirectiveBinding<boolean>) => {
  // Use rAF to work inside popper element and avoid jumping while positioning
  requestAnimationFrame(() => {
    if (binding.value || typeof binding.value === 'undefined') {
      setNativeFocused(el);

      if (binding.modifiers.select) {
        (el as HTMLInputElement).select();
      }
    } else {
      setNativeFocused(el, false);
    }
  });
};

export const vFocus: ObjectDirective = {
  mounted(el: HTMLElement, binding: FocusDirectiveBinding) {
    handle(el, binding);
  },

  updated(el: HTMLElement, binding: FocusDirectiveBinding) {
    if (Boolean(binding.value) === Boolean(binding.oldValue)) {
      return;
    }

    handle(el, binding);
  },
};
