<script lang="ts" setup>
import {
  shallowRef,
  computed,
  type VNode,
  type VNodeProps,
  type ComponentPublicInstance,
} from 'vue';
import { useFloating, flip, autoUpdate, shift, offset, type Middleware } from '@floating-ui/vue';
import { onClickOutside, unrefElement } from '@vueuse/core';
import { useZIndex, useFocusScope } from '../../composables';
import { ObUnmountableTeleportWithTransition } from '../internal';

interface Props {
  active: boolean;
  floatingMiddleware?: (middleware: Middleware[]) => Middleware[];
}

defineOptions({
  inheritAttrs: false,
});

const { active = false, floatingMiddleware } = defineProps<Props>();

const emit = defineEmits<{
  clickOutside: [event: PointerEvent];
}>();

defineSlots<{
  default?: () => VNode;
  host?: (props: {
    hostProps: VNodeProps & { ref: (el: Element | ComponentPublicInstance | null) => void };
  }) => VNode;
}>();

const { zIndex } = useZIndex({ active: computed(() => active) });

const hostRef = shallowRef();
const containerRef = shallowRef<HTMLDivElement | null>(null);

const middleware = computed<Middleware[]>(() => {
  let result = [offset(8), flip(), shift()];

  if (typeof floatingMiddleware === 'function') {
    result = floatingMiddleware(result);
  }

  return result;
});

const { floatingStyles } = useFloating(hostRef, containerRef, {
  placement: 'bottom-start',
  middleware,
  whileElementsMounted: autoUpdate,
  open: active,
  transform: false,
});

onClickOutside(
  containerRef,
  (event) => {
    emit('clickOutside', event);
  },
  { ignore: [hostRef] },
);

useFocusScope(containerRef);

const hostProps = computed(() => {
  return {
    ref: (el: Element | ComponentPublicInstance | null) => {
      hostRef.value = unrefElement(el as any); // TODO: how not to cast no any? Element vs HTMLElement
    },
  };
});

defineExpose({
  $el: containerRef,
});
</script>

<template>
  <slot name="host" v-bind="{ hostProps }" />
  <ObUnmountableTeleportWithTransition
    :active
    :enter-from-class="$style.enterFrom"
    :enter-active-class="$style.enterActive"
    :leave-active-class="$style.leaveActive"
    :leave-to-class="$style.leaveTo"
  >
    <div
      v-if="active"
      ref="containerRef"
      :style="{ ...floatingStyles, zIndex }"
      :class="$style.container"
      v-bind="$attrs"
    >
      <slot />
    </div>
  </ObUnmountableTeleportWithTransition>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.enterActive,
.leaveActive {
  transition-property: opacity, transform;
  transition-duration: 0.2s;
}

.enterActive {
  transition-timing-function: ease-out;
}

.leaveActive {
  transition-timing-function: ease-in;
}

.enterFrom,
.leaveTo {
  opacity: 0;
  transform: scale(0.9);
}

.container {
  box-sizing: border-box;
  max-height: 480px;
  width: auto;
  border-radius: 12px;
  background: #fff;
  color: colors.$primary;
  border: 1px solid colors.$surface-6;
  box-shadow: 0px 0px 18px 0px rgba(2, 17, 72, 0.2);
  font-family: typography.$font-family-primary;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>
