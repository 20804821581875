<script lang="ts" setup>
import { provide, ref } from 'vue';
import { ABSTRACT_DISCLOSURE_CONTEXT } from './shared';

const expanded = defineModel<boolean>('expanded', { default: false });

function expand() {
  if (expanded.value) {
    return;
  }
  expanded.value = true;
}

function collapse() {
  if (!expanded.value) {
    return;
  }
  expanded.value = false;
}

function toggle() {
  expanded.value = !expanded.value;
}

const buttonId = ref(null);
const panelId = ref(null);
const buttonRef = ref<HTMLElement | null>(null);
const panelRef = ref<HTMLElement | null>(null);

provide(ABSTRACT_DISCLOSURE_CONTEXT, {
  expanded,
  expand,
  collapse,
  toggle,
  buttonId,
  panelId,
  buttonRef,
  panelRef,
});
</script>

<template>
  <slot v-bind="{ expanded, expand, collapse, toggle }" />
</template>
