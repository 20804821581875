<script lang="ts" setup>
import { inject, type VNode } from 'vue';
import { ACTION_LIST_CONTEXT } from './action-list-context';
import ObActionListGroupContextProvider from './ob-action-list-group-context-provider.vue';

type Props = {
  title?: string | null;
};

const { title } = defineProps<Props>();

defineSlots<{
  default?: () => VNode;
}>();

const { listRole } = inject(ACTION_LIST_CONTEXT, {});
</script>

<template>
  <li :role="listRole ? 'none' : undefined" :class="$style.root">
    <div v-if="title" role="presentation" aria-hidden="true" :class="$style.title">
      <span>{{ title }}</span>
    </div>
    <ObActionListGroupContextProvider :title="title">
      <slot />
    </ObActionListGroupContextProvider>
  </li>
</template>

<style lang="scss" module>
.root {
  padding: 0;
  margin: 0;

  &:not(:first-child) {
    margin-top: 8px;
  }
}

.title {
  color: #021148;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 4px 0;
}
</style>
